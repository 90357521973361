@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 100vh;
    border: 1px solid transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.react-datepicker__input-container {
  height: 100%;
}

.ql-toolbar.ql-snow {
  background-color: #EAECF0;
  border-bottom: 0px;
  border: 1px solid #EAECF0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ql-container.ql-snow {
  border-top: 0px;
  border: 1px solid #EAECF0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #FFFFFF;
  min-height: inherit;
}

.ck-toolbar {
  background-color: #EAECF0 !important;
  border-bottom: 0px !important;
  border: 1px solid #EAECF0 !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.ck-editor__main .ck-editor__editable_inline {
  border-top: 0px !important;
  border: 1px solid #EAECF0 !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  background: #FFFFFF !important;
  min-height: 500px !important;
}

.ql-container.ql-snow .ql-editor {
  min-height: inherit;
}

.quill.has-error .ql-toolbar.ql-snow {
  border-color: #F04438 !important;
}

.quill.has-error .ql-container.ql-snow {
  border-color: #F04438 !important;
}

.template-content {
  border: 1px solid transparent;
  border-radius: 6px;
}
.template-content.has-error {
  border-color: #F04438 !important;
}

.my-select__input {
  outline: none !important;
  box-shadow: none !important;
  color: #98A2B3 !important;
}
.my-select__input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
  content: "Small";
  font-size: 0.75em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
  content: "Normal";
  font-size: 1em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
  content: "Large";
  font-size: 1.5em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
  content: "Huge";
  font-size: 2.5em !important;
}

.react-datepicker__day {
  color: #344054 !important;
  position: relative;
}

.react-datepicker__day--outside-month {
  color: #667085 !important;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
  cursor: not-allowed !important;
}

.react-datepicker__day:hover {
  border-radius: 20px !important;
}

.react-datepicker__day--today {
  border-radius: 20px !important;
  background-color: #F2F4F7 !important;
}

.react-datepicker__day--today:after {
  content:'';
  display: inline-block;
  bottom: 3px;
  left: 50%;
  border-radius: 20px;
  background-color: #00BD52 !important;
  height: 4px;
  width: 4px;
  position: absolute;
  transform: translate(-50%, 0);
}

.react-datepicker__day--selected {
  border-radius: 20px !important;
  background-color: #00BD52 !important;
  color: #FFF !important;
  font-weight: 700 !important;
}

.react-datepicker__header {
  background: #FFF !important;

}

.react-datepicker__current-month {
  color: #344054 !important;
  font-weight: 600 !important;
}

.react-datepicker__navigation-icon:before {
  border-color: #667085 !important;
}

.react-datepicker__day-name {
  color: #344054 !important;
}

.react-datepicker__navigation {
  top: 9px !important;
}

.center-content:before, .center-content:after {
  content: "";
  clear: both;
  display: table;
}

.center-content ol {
  padding-left: 15px;
}

.steps .step-history:after {
  color: #fff !important;
  background-color: #00bd52 !important;
}

.steps .step-history:before {
  width: 5px !important;
  background-color: #00bd52 !important;
}
